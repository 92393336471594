"use strict";

angular.module("informaApp")
    .service("LineChartHelper", ['d3', 'ChartElementsHelper', 'Legend', function (d3, ChartElementsHelper, Legend) {
        var result = {};

        result.Chart = function (canvasElement, tooltipTemplate, options = {}) {
            this.colors = [
                '#8334c1', '#ef2956', '#64b947', '#ffa415', '#e27bed',
                '#ff7214', '#ffe74c', '#b2ba47', '#c43b97', '#ff3014',
                '#6985db', '#9f4141', '#67c0db', '#752525', '#67dbba',
                '#1d22f1', '#41e600', '#3b927f', '#2c794b', '#d8bfea'
            ];

            this.defaultColors = _.merge([], this.colors);

            var _this = this;

            var config = {
                height: 400,
                width: canvasElement.offsetWidth,
                margin: {top: 30, right: 0, bottom: 60, left: 60},
                legendHeight: 50
            };

            var activeElementId = null;

            config.bottomContainerWidth = options.isLegendAtTheBottom ? config.width - config.margin.left : config.width * 2 / 3;

            config.bottomContainerMargin = {left: 10, top: config.height - config.margin.bottom + 15};
            config.secondDividerMargin = {top: 27};

            var resultHeight = config.height + config.margin.top;
            var resultWidth = config.width;

            var svg = d3.select(canvasElement)
                .append("svg")
                .attr("height", resultHeight)
                .attr("width", resultWidth)
                .attr("class", "chart svg-container");

            var chart = svg.append("g")
                .attr("transform", "translate(" + config.margin.left + ", " + config.margin.top + ")");

            var bottomAxisContainer = chart.append("g")
                .attr("transform", "translate(" + config.bottomContainerMargin.left + ", " + config.bottomContainerMargin.top + ")")
                .attr("width", config.bottomContainerWidth);

            var dividerTop = createDivider(0, config.bottomContainerWidth, 0);
            var dividerBottom = createDivider(0, config.bottomContainerWidth, config.secondDividerMargin.top);

            var labels = null;

            var tip = d3.tip()
                .attr("class", "d3-tip auto")
                .offset([-10, 0])
                .html((x) => tooltipTemplate(x, _this.colors));

            chart.call(tip);

            this.save = function (name, onError) {
                saveSvgAsPng(canvasElement.querySelector(".svg-container"), name + ".png", {scale: 1}, onError);
            };

            this.update = function (data, showNumbers, xAxisNames, getYValue, yAxisOptions, disableAnimation, isLegendEvent, legendItemOffset = 0) {
                var yScale = createYAxis(yAxisOptions);
                var xScale = drawXAxis(xAxisNames);

                svg.selectAll('path.line, circle.circle').remove();

                if (labels && !isLegendEvent) {
                    svg.selectAll('line.anchor, text.point-label').remove();
                }

                var calculateX = function (d) {
                    return getX(xScale, d.x);
                };

                var calculateY = function (d) {
                    return getY(yScale, getYValue(d));
                };

                drawLines(data, disableAnimation, calculateX, calculateY, function (x) {
                    return !x.onFront;
                }, function (x) {
                    return false;
                });
                drawLines(data, disableAnimation, calculateX, calculateY, function (x) {
                    return x.onFront;
                }, function (x) {
                    return x.onFront;
                });

                var points = getDataForPoints(data, getYValue);

                chart.selectAll("dot")
                    .data(points)
                    .enter()
                    .append('circle')
                    .attr("class", "circle")
                    .attr("data-x", function (d) {
                        return d.x
                    })
                    .attr("r", function (d) {
                        return d.isHighlighted ? 4 : 3;
                    })
                    .attr("fill", function (d) {
                        return d.isHighlighted ? d.color : "";
                    })
                    .attr("cx", function (d) {
                        return getX(xScale, d.x);
                    })
                    .attr("cy", function (d) {
                        return getY(yScale, getYValue(d));
                    })
                    .on('mouseover', function (d, i) {
                        highlightPoints(d.x, d.color);

                        tip.attr('class', 'd3-tip auto');
                        tip.show(d);
                    })
                    .on('mouseleave', function (d) {
                        highlightPoints(d.x, null);
                        tip.destroy();
                    });

                if (showNumbers && !isLegendEvent) {
                    labels = drawLabels(points, xScale, yScale, getYValue);
                }

                updateLabelsHighlighting();

                if (!isLegendEvent) {
                    drawLegend(data, showNumbers, xAxisNames, getYValue, yAxisOptions, legendItemOffset);
                }
            };

            this.destroy = () => svg.remove();

            function drawLabels(points, xScale, yScale, getYValue) {
                var labelRects = points.map(function (d) {
                    return {
                        x: getX(xScale, d.x),
                        y: getY(yScale, getYValue(d)),
                        width: 0,
                        height: 0,
                        name: getYValue(d),
                        context: d
                    }
                });

                var textShadow = '2px 0 0 white, -2px 0 0 white, 0 2px 0 white, 0 -2px 0 white, 1px 1px white, -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white';

                var labels = chart.selectAll('.point-label')
                    .data(labelRects)
                    .enter()
                    .append('text')
                    .attr('class', 'point-label')
                    .text(function (d) {
                        return d.name;
                    })
                    .attr('x', function (d) {
                        return (d.x);
                    })
                    .attr('y', function (d) {
                        return (d.y);
                    })
                    .style('font-size', '12px')
                    .style('text-shadow', textShadow)
                    .each(function (d, i) {
                        var box = this.getBBox();

                        labelRects[i].width = box.width;
                        labelRects[i].height = box.height;
                    });

                var anchors = chart.selectAll('.anchor')
                    .data(labelRects)
                    .enter()
                    .append("line")
                    .attr("class", "anchor")
                    .attr("x1", function (d) {
                        return (d.x);
                    })
                    .attr("y1", function (d) {
                        return (d.y);
                    })
                    .attr("x2", function (d) {
                        return (d.x);
                    })
                    .attr("y2", function (d) {
                        return (d.y);
                    })
                    .attr("stroke-width", 0.6)
                    .attr("stroke", "gray");

                removeOverlapping(labels, anchors, labelRects);

                return labels;
            }

            function removeOverlapping(labels, anchors, labelRects) {
                var anchorRects = labelRects.map(createAnchorFromLabel);

                d3.labeler()
                    .label(labelRects)
                    .anchor(anchorRects)
                    .width(resultWidth)
                    .height(resultHeight)
                    .start(2000);

                labels
                    .attr('x', function (d) {
                        return (d.x);
                    })
                    .attr('y', function (d) {
                        return (d.y);
                    });

                anchors
                    .attr('x2', function (d) {
                        return (d.x);
                    })
                    .attr('y2', function (d) {
                        return (d.y);
                    });
            }

            function createAnchorFromLabel(label) {
                var anchorRadius = 10;

                return {
                    x: label.x,
                    y: label.y,
                    r: anchorRadius
                };
            }

            function drawLines(data, disableAnimation, calculateX, calculateY, conditionDraw, conditionHighlight) {
                var lineGen = d3.svg.line()
                    .x(calculateX)
                    .y(calculateY);

                for (var i = 0; i < data.length; i++) {
                    if (data[i].disabled || !conditionDraw(data[i])) continue;

                    var path = chart.append('svg:path')
                        .attr('class', 'line')
                        .attr('d', lineGen(data[i].data))
                        .attr('stroke', _this.colors[i % _this.colors.length])
                        .attr('stroke-width', conditionHighlight(data[i]) ? 3 : 2)
                        .attr('fill', 'none');

                    if (!disableAnimation) {
                        var totalLength = path.node().getTotalLength();

                        path
                            .attr("stroke-dasharray", totalLength + " " + totalLength)
                            .attr("stroke-dashoffset", totalLength)
                            .transition()
                            .duration(1000)
                            .ease("linear")
                            .attr("stroke-dashoffset", 0);
                    }
                }
            }

            function drawXAxis(xAxesNames) {
                svg.select("g.x.axis").remove();

                var xAxisContainer = bottomAxisContainer.append("g")
                    .attr("transform", "translate(0, 0)")
                    .attr("class", "x axis")
                    .style("font-size", "12px");

                var xScale = d3.scale.ordinal()
                    .rangeRoundBands([0, config.bottomContainerWidth])
                    .domain(xAxesNames);

                var xAxis = d3.svg.axis()
                    .scale(xScale)
                    .orient("bottom");

                xAxisContainer
                    .call(xAxis);

                return xScale;
            }

            function highlightPoints(x, color) {
                svg.selectAll("circle.circle[data-x='" + x + "']").attr("fill", color);
            }

            function createDivider(x1, x2, y) {
                return bottomAxisContainer.append("line")
                    .classed("divider", true)
                    .attr("x1", x1)
                    .attr("y1", y)
                    .attr("x2", x2)
                    .attr("y2", y);
            }

            function getDataForPoints(data, getYValue) {
                var points = [];

                function addPoint(condition) {
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].disabled || !condition(data[i])) continue;

                        for (var j = 0; j < data[i].data.length; j++) {
                            var group = data[i].data[j];
                            if (!_.find(points, function (p) {
                                return p.x == group.x && getYValue(p) == getYValue(group);
                            })) {
                                group.name = data[i].name;
                                group.color = _this.colors[i];
                                group.isHighlighted = data[i].onFront;

                                points.push(group);
                            }
                        }
                    }
                }

                addPoint(function (d) {
                    return d.onFront;
                });
                addPoint(function (d) {
                    return !d.onFront;
                });

                return points;
            }

            function drawLegend(data, showNumbers, xAxisNames, getYValue, yAxisOptions, legendItemOffset) {
                svg.select('g.legend').remove();

                const legend = options.isLegendAtTheBottom ? getHorizontalLegend(data) : getLegend(data, legendItemOffset);

                legend.onClick = function (d, i, offset) {
                    _this.update(data, showNumbers, xAxisNames, getYValue, yAxisOptions, true, false, offset);
                };

                legend.onMouseEnter = function (d, i) {
                    activeElementId = i;

                    removeOnFrontField(data);
                    data[i].onFront = true;

                    _this.update(data, showNumbers, xAxisNames, getYValue, yAxisOptions, true, true);
                };

                legend.onMouseLeave = function () {
                    activeElementId = null;

                    removeOnFrontField(data);

                    _this.update(data, showNumbers, xAxisNames, getYValue, yAxisOptions, true, true);
                };
            }

            function getHorizontalLegend(data) {
                const legendContainer = svg
                    .append('g')
                    .attr('class', 'legend')
                    .attr('width', config.width)
                    .attr('height', config.legendHeight)
                    .attr('transform', ChartElementsHelper.translate(0, 0));

                var legend = new Legend(legendContainer, data, _this.colors, {horizontal: true});

                const positionX = config.margin.left + config.bottomContainerWidth / 2 - legend.getWidth() / 2;
                legendContainer.attr('transform', ChartElementsHelper.translate(positionX, 0));

                return legend;
            }

            function getLegend(data, legendItemOffset) {
                var marginLeft = config.bottomContainerWidth + config.bottomContainerMargin.left + 45;

                var legendContainer = chart
                    .append('g')
                    .attr('class', 'legend')
                    .attr('width', config.width / 3)
                    .attr('height', config.height)
                    .attr('transform', ChartElementsHelper.translate(marginLeft, 0));

                return new Legend(legendContainer, data, _this.colors, { maxItems: 10, legendItemOffset });
            }

            function updateLabelsHighlighting() {
                if (!labels) {
                    return;
                }

                labels.style('font-size', function (d) {
                    return d.context.isHighlighted ? '14px' : '12px';
                })
            }

            function removeOnFrontField(data) {
                _.forEach(data, function (x) {
                    x.onFront = null;
                });
            }

            function getX(xScale, x) {
                return xScale(x) + xScale.rangeBand() / 2 + config.bottomContainerMargin.left;
            }

            function getY(yScale, y) {
                return yScale(y);
            }

            function createYAxis(yAxisOptions) {
                chart.select("g.y.axis").remove();
                chart.append("g").attr("class", "y axis");

                var formatValue = (function (d) {
                    return d + yAxisOptions.prefix
                });

                var yScale = d3.scale.linear()
                    .domain([yAxisOptions.min || 0, yAxisOptions.max])
                    .range([config.height - config.margin.bottom, 0]);

                var yAxis = d3.svg.axis()
                    .scale(yScale)
                    .orient("left")
                    .tickFormat(formatValue);

                chart.select(".y.axis")
                    .call(yAxis);

                return yScale;
            }
        }

        return result;
    }]);
